import React from "react";

const ThemeColorContext = React.createContext({
  primaryColors: {
    primary50: "#E5F6FF",
    primary100: "#CCF0FF",
    primary400: "#33D6FF",
    primary500: "#00ACCE",
    primary600: "#00A5CE",
    primary700: "#007599",
    primary800: "#004B66",
  },
  secondaryColors: {
    secondary50: "#E5FFF5",
    secondary100: "#CCFFEB",
    secondary400: "#33FFAD",
    secondary500: "#00CC79",
    secondary700: "#00995C",
    secondary800: "#00663D",
  },
  defaultColors: {
    white: "#FFFFFF",
    black: "#000000",
  },
  wildMeColors: {
    cyan: "#00ACCE",
    teal: "#00CC79",
    cyan50: "#E5F6FF",
    cyan100: "#CCF0FF",
    cyan200: "#99E4FF",
    cyan300: "#66DBFF",
    cyan400: "#33D6FF",
    cyan500: "#00ACCE",
    cyan600: "#00A5CE",
    cyan700: "#007599",
    cyan800: "#004B66",
    cyan900: "#002433",
    teal50: "#E5FFF5",
    teal100: "#CCFFEB",
    teal200: "#99FFD6",
    teal300: "#66FFC2",
    teal400: "#33FFAD",
    teal500: "#00CC79",
    teal600: "#00CC7A",
    teal700: "#00995C",
    teal800: "#00663D",
    teal900: "#00331F",
  },
  grayColors: {
    gray50: "#F2F2F3",
    gray100: "#E5E6E7",
    gray200: "#C9CCCF",
    gray300: "#AFB3B7",
    gray400: "#94999E",
    gray500: "#798086",
    gray600: "#616668",
    gray700: "#494D50",
    gray800: "#303336",
    gray900: "#181A1B",
  },
  statusColors: {
    green50: "#E9FBF3",
    green100: "#D4F7E7",
    green200: "#A8F0CF",
    green300: "#7DE8B8",
    green400: "#52E0A0",
    green500: "#26D988",
    green600: "#1FAD6D",
    green700: "#178252",
    green800: "#057536",
    green900: "#082B1B",
    red50: "#FBE9EB",
    red100: "#F8D7DA",
    red200: "#F0A8AE",
    red300: "#E87D86",
    red400: "#E0525D",
    red500: "#D92635",
    red600: "#A32933",
    red700: "#821721",
    red800: "#570F15",
    red900: "#2B080B",
    yellow50: "#FFF9E6",
    yellow100: "#FFF2CD",
    yellow200: "#FFE599",
    yellow300: "#FFD966",
    yellow400: "#FFCC33",
    yellow500: "#FFB800",
    yellow600: "#E5AC00",
    yellow700: "#997300",
    yellow800: "#664C00",
    yellow900: "#342700",
    indigo50: "#F0E7FD",
    indigo100: "#E0CFFC",
    indigo200: "#C2A1F8",
    indigo300: "#A372F5",
    indigo400: "#8440F2",
    indigo500: "#6511EE",
    indigo600: "#510DBF",
    indigo700: "#3D0A91",
    indigo800: "#290760",
    indigo900: "#140330",
    blue50: "#E6F0FF",
    blue100: "#CDE0FE",
    blue200: "#9AC3FE",
    blue300: "#68A4FD",
    blue400: "#3586FD",
    blue500: "#0267FD",
    blue600: "#0253CA",
    blue700: "#023E97",
    blue800: "#012A65",
    blue900: "#011432",
  },
});

export default ThemeColorContext;
