import React, { useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormattedMessage } from "react-intl";
import Chip from "../Chip";
import BrutalismButton from "../BrutalismButton";
import ThemeContext from "../../ThemeColorProvider";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

const Sidebar = observer(
  ({ setFilterPanel, searchQueryId, queryID, tempFormFilters = [], store }) => {
    const theme = React.useContext(ThemeContext);
    const [show, setShow] = useState(false);
    const sidebarWidth = 400;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [, setSearchParams] = useSearchParams();

    const num = () => (queryID ? 1 : tempFormFilters.length);

    const handleCopy = () => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(searchQueryId)
          .then(() => {
            alert(`Query ID: ${searchQueryId} copied to clipboard!`);
          })
          .catch((err) => {
            console.error("Failed to copy text: ", err);
          });
      } else {
        alert("Clipboard API not supported or permissions denied.");
      }
    };

    return (
      <>
        <Button
          onClick={handleShow}
          style={{
            borderRadius: "0 0 5px 5px",
            width: "200px",
            height: "45px",
            backgroundColor: "white",
            color: "black",
            border: "none",
            position: "fixed",
            fontWeight: "500",
            top: "40%",
            right: show ? `${sidebarWidth}px` : "0px",
            zIndex: "1030",
            transform: "translateY(-50%) translateX(42%) rotate(90deg)",
            transition: "right 0.3s ease-in-out",
            padding: "8px 10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormattedMessage id="APPLIED_FILTERS" />
          <div
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              backgroundColor: "red",
              color: "white",
              marginLeft: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {num()}
          </div>
        </Button>

        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          style={{ width: `${sidebarWidth}px`, borderRadius: "10px 0 0 10px" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <FormattedMessage id="APPLIED_FILTERS" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {queryID ? (
              <div
                style={{
                  backgroundColor: "#e5f6ff",
                  color: "black",
                  borderRadius: "15px",
                  padding: "10px 20px",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                  marginTop: "10px",
                }}
              >
                <p>{`Search Query ID ${queryID} applied.`}</p>
              </div>
            ) : (
              <div style={{ overflowY: "auto" }}>
                {tempFormFilters.map((filter, index) => (
                  <Chip key={index}>{filter}</Chip>
                ))}
              </div>
            )}

            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                padding: "10px 0",
              }}
            >
              <BrutalismButton
                onClick={() => {
                  handleClose();
                  handleCopy();
                }}
                noArrow={true}
                backgroundColor={theme.primaryColors.primary700}
                borderColor={theme.primaryColors.primary700}
                color="white"
              >
                <FormattedMessage id="FILTER_COPY" defaultMessage={"Copy"} />
              </BrutalismButton>
              <BrutalismButton
                onClick={() => {
                  setFilterPanel(true);
                  handleClose();
                }}
                backgroundColor={theme.primaryColors.primary700}
                borderColor={theme.primaryColors.primary700}
                color="white"
                noArrow={true}
              >
                <FormattedMessage
                  id="FILTER_EDIT_FILTER"
                  defaultMessage={"Edit"}
                />
              </BrutalismButton>
              <BrutalismButton
                borderColor={theme.primaryColors.primary700}
                color={theme.primaryColors.primary700}
                noArrow
                onClick={() => {
                  store.resetFilters();
                  handleClose();
                  setSearchParams(new URLSearchParams());
                  window.location.reload();
                }}
              >
                <FormattedMessage
                  id="FILTER_RESET_FILTER"
                  defaultMessage={"Reset"}
                />
              </BrutalismButton>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  },
);

export default Sidebar;
